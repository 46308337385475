import { ApplicationPage } from 'app/types';
import PageHeader from 'common/components/page-header';
import { Routes } from 'common/router/constants';
import { ReferrerUseOfTerms } from 'domains/profile/containers/referral-program/containers/referrer-use-of-terms';

const TITLE = 'Terms of Use';

const ReferrerTermsOfUsePage: ApplicationPage = () => {
  return (
    <>
      <PageHeader
        title={TITLE}
        defaultBackRoute={Routes.ProfileReferralProgram}
        enableMarginBottom
      />
      <ReferrerUseOfTerms />
    </>
  );
};

ReferrerTermsOfUsePage.getMeta = () => ({ title: TITLE });

export default ReferrerTermsOfUsePage;
